<template>
    <div class="conteiner mx-5">
        <h1>Relatório Geral</h1>
            <b-form-group label="Using options array:" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selected_itens"
                    :options="itens_checkbox"
                    :aria-describedby="ariaDescribedby"
                ></b-form-checkbox-group>
            </b-form-group>
        <hr>
        <ul>
            <li v-for="item in lista_formularios" :key="item.id">
                {{item.nome_curso}}
            </li>
        </ul>

        <hr>

        <div v-for="curso in lista_formularios" :key="curso.id" >
            <h1>{{curso.nome_curso}}</h1>

            <div v-if="selected_itens.includes('item_1_3')" class="mt-5 mb-5">
                <h6>1.3 Justificativa para oferta do curso</h6>
                {{pulaLinha(curso.formulario_especifico.item_1_3)}}
            </div>

            <div v-if="selected_itens.includes('item_1_4')" class="mt-5 mb-5">
                <h6>1.4. Relevância da oferta </h6>
                {{curso.formulario_especifico.item_1_4}}
            </div>

            <div v-if="selected_itens.includes('item_1_5_1')" class="mt-5 mb-5">
                <h6>1.5.1. Objetivo Geral</h6>
                {{curso.formulario_especifico.item_1_5_1}}
            </div>

            <div v-if="selected_itens.includes('item_1_5_2')" class="mt-5 mb-5">
                <h6>1.5.2. Objetivos Específicos</h6>
                {{curso.formulario_especifico.item_1_5_2}}
            </div>

            <div v-if="selected_itens.includes('item_3')" class="mt-5 mb-5">
                <h6>3. Perfil profissional de conclusão</h6>
                {{curso.formulario_especifico.item_3}}
            </div>

            <div v-if="selected_itens.includes('item_3_1')" class="mt-5 mb-5">
                <h6>3.1 Habilidades e competências desenvolvidas na formação</h6>
                {{curso.formulario_especifico.item_3_1}}
            </div>
            
            <div v-if="selected_itens.includes('item_4_2_1')" class="container mt-5 mb-5" >
                <div class="row" v-for="unidade in curso.formulario_especifico.item_4_2_1" :key="unidade.id">
                    <h4 style="background: aliceblue; padding: 10px; mborder-radius: 7px;">{{unidade.nome_unidade}} | {{unidade.semestre}} |  {{unidade.modalidade}}</h4>

                    <p>
                        <strong>Ementa:</strong>
                        {{filterLatex(unidade.ementa)}}
                    </p>

                    <div class="row" v-for="(competencia, id) in unidade.competencias" :key="competencia.id">
                        <p>
                            <strong>Competência {{id+1}}: {{filterLatex(competencia.nome)}} </strong> <br>
                            <strong>Conhecimento: </strong> {{filterLatex(competencia.conhecimento)}} <br>
                            <strong>Habilidade: </strong> {{filterLatex(competencia.habilidade)}} <br>
                            <strong>Atitude: </strong> {{filterLatex(competencia.atitude)}} <br>
                        </p>
                    </div>

                    <p>
                        <strong>Práticas:</strong>
                        {{filterLatex(unidade.praticas)}}
                    </p>

                    <p>
                        <strong>Avaliação:</strong>
                        {{filterLatex(unidade.avaliacao)}}
                    </p>

                    <p>
                        <strong>Bibliografia Básica:</strong>
                        <ul><li v-for="bib in unidade.bibliografia_basica" :key="bib.id">{{filterLatex(bib)}}</li></ul>
                    </p>

                    <p>
                        <strong>Bibliografia Complementar:</strong>
                        <ul><li v-for="bib in unidade.bibliografia_complementar" :key="bib.id">{{filterLatex(bib)}}</li></ul>
                    </p>

                    
                </div>
            </div>
            <hr>
        </div>
        
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    data(){
        return{
            formularios: [],
            selected_itens: [],
            itens_checkbox:['item_1_3', 'item_1_4', 'item_1_5_1', 'item_1_5_2', 'item_3', 'item_3_1', 'item_4_1', 'item_4_10', 'item_4_2', 'item_4_2_1', 'item_4_3']
        }
    },
    computed:{
        lista_formularios: function(){
            let formularios_organizados = []
            let todos_formularios = this.formularios.filter(x => x.id !== 'forms_geral')
            todos_formularios.forEach(x =>{
                // todos que NÃO são EAD e NÃO são teste
                if(!x.nome_curso.includes('TESTE') && !x.nome_curso.includes('EAD')){
                    formularios_organizados.push(x)
                }
            })
            todos_formularios.forEach(x => {
                if(x.nome_curso.includes('EAD')){
                    formularios_organizados.push(x)
                }
            })
            // todos_formularios.forEach(x => {
            //     if(x.nome_curso.includes('TESTE')){
            //         formularios_organizados.push(x)
            //     }
            // })
            return formularios_organizados
        }
    },
    methods:{
        filterLatex(text){
            try{
                return text.replaceAll("%", "").replaceAll("$", "").replaceAll("&", "").replaceAll("/", "")
            }
            catch(e){
                console.log(e) 
            return ''
            }
            
        },
        itemVisivel(item){
            this.itens_visiveis.includes(item)
        },

        pulaLinha(text){
            text = text.replaceAll(/(\r\n|\r|\n)/g, '*************' )
            return text
        }
    },
    mounted(){
        db.collection("/nadir")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let dados_formulario = doc.data()
                dados_formulario['id'] = doc.ref.id
                console.log(dados_formulario)
                try{
                    this.formularios.push(dados_formulario)
                }catch(e){
                    console.log(e)
                    console.log('Erro no doc:', dados_formulario)
                }
            });
            
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

        // // Solicitando os campus
        // db.collection("/campus_uninove")
        // .get()
        // .then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         let campus = doc.data()
        //         this.campus_uninove.push(campus)
        //     });            
        // })
        // .catch((error) => {
        //     console.log("Error getting documents: ", error);
        // });
    }
}
</script>

<style scoped>
*{
    text-align: left;
}
</style>